/* Below animations are fore modal created using React-Modal */

.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.app-loader {
    position: absolute;
    padding: 1em;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 1;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    align-items: center;
    user-select: none;
    will-change: opacity;
    z-index: 1000;
    border-radius: 5px;
}

.spin {
    position: absolute;
    top: 40%;
    left: 45%;
    width: 80px;
    height: 80px;
}

.spin:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #fff transparent #fff transparent;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
